import styled from 'styled-components'
import { Box, HeadingStyled, SpanStyled, TextStyled } from './Elements';


export const sliceProps = (props, sub) => {
    const { children, ...otherProps } = props;
    sub.forEach(prop => delete otherProps[prop]);
    return otherProps;
}


export const ButtonWithIcon = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ButtonWrapper {...sliceProps(props, unwantedProps)} className='d-flex align-items-center'>
            <button {...sliceProps(props, unwantedProps)} className={`${!props.icon ? 'Btn-icon' : ''} `} autoComplete='false'>
                {props.icon && (
                    <props.icon width='20px' />
                )}
                <SpanStyled color='var(--drColor)' size='14px'>{props.label ? props.label : ''}</SpanStyled>
            </button>
        </ButtonWrapper>
    )
}
export const ContactButton = (props) => {
    const unwantedProps = ['icon', 'label'];
    return (
        <ContactWrapper {...sliceProps(props, unwantedProps)}>
            <button {...sliceProps(props, unwantedProps)} className={` ${!props.icon ? 'Btn-icon' : ''}`} autoComplete='false'>
                {props.icon && (
                    <props.icon width='22px' />
                )}
                <HeadingStyled color='var(--drColor)' transform='math-auto' size='14px' weight='normal'>{props.label ? props.label : ''}</HeadingStyled>
            </button>
        </ContactWrapper>
    )
}
export const InputWithIcon = (props) => {
    const unwantedProps = ['icon', "label"];
    return (
        <InputStyledWrap className='position-relative' >
            <InputStyled {...sliceProps(props, unwantedProps)} className={`w-100 ${!props.icon ? 'no-icon' : ''}`} autoComplete='false' />
            {props.icon && (
                <props.icon width='18px' className='position-absolute input-svg' />
            )}
        </InputStyledWrap>
    )
}
export const TextWithIcon = (props) => (
    <TextWithStyledWrapper color={props.color} className={`d-flex ${props.desc ? 'align-items-start' : 'align-items-center'}`}>
        <Box className='iconBox d-flex align-items-center justify-content-center'>
            <props.icon width='20px' height='20px' />
        </Box>
        <Box>
            <TextStyled className={`mb-0 ${props.clName ? props.clName : ""}`} weight={400} size={props.headingSize ? props.headingSize : '14px'}
                color={!props.alsotextColor ? 'var(--themeColor)' : 'var(--textGrey)'}
                dangerouslySetInnerHTML={{ __html: props.title }}>
            </TextStyled>
            {props.desc ?
                <SpanStyled className='text-uppercase' color={'var(--textgrey)'} size='12px' weight={300}>
                    {props?.desc}
                </SpanStyled>
                : null}
        </Box>
    </TextWithStyledWrapper>
)

const TextWithStyledWrapper = styled.div`
    .iconBox{
        margin-right: 10px;
        body.rtl &{
            margin-right: 0px;
            margin-left: 10px;
        }
    }
    
  svg {
      [stroke] {
          stroke: ${({ color }) => color ? color : ''};
      }
  }
`
export const InputStyled = styled.input`
    background:var(--bgColor);
    border: none;
    border-radius: 8px;
    height: 43px;
    // color: var(--textGrey);
    &:not(.no-icon) {
        padding-left: 50px;
    }
    &.no-icon {
        padding-left: 18px;
    }
    padding-right: 18px;
    outline: none;
    &,&::placeholder {
        font-size: 14px;
        color: var(--textPalceholder);
    }
`
export const InputStyledWrap = styled.div`
    border-radius: 8px;
    background: var(--white);
    svg {
    left: 13px;
    top: 12px;
    height: 20px;
    }
`


export const ContactWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content:${props => props.content ? props.content : ''};
    
`
export const ButtonWrapper = styled.div`
button {
    background: var(--themeBgGradient);
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5px;
    // width:150px;
    padding:6px 10px;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    border:none;
    outline: none;
    color: var(--white);
    overflow: hidden;
    &::before, &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: -100%;
        left: 0;
        background: var(--themeColor);
        z-index: -1;
        transition: all 400ms ease-in;
    }
    svg{
        [stroke]{
            stroke: var(--white);
        }
        [fill]{
            fill: var(--white);
        }
    }
    &:hover{
        color: var(--white);
        outline:1px solid var(--themeColor);
        background: none;
        svg{
        [stroke]{
            stroke: var(--white);
        }
        [fill]{
            fill: var(--white);
        }
    }
        &::before,&::after{
            bottom: 0;
        }
        &::before {
            opacity: 0.3;
        }
        &::after {
            transition-delay: 0.3s;
        }
    }
}

  
`