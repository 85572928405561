import styled from "styled-components";

export const ContactWapper = styled.section`
    position:relative;
    background:var(--white);

    .location-card{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }
    .locationCol{
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        & > *{
            width: 100%;
        }
    }
    .numDir{
        direction: ltr;
    }
    @media (max-width: 991px){
        .locationCol:first-child{
            margin-bottom: 40px;
        }
    }
`